import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { userService } from "../services/user"
import { showService } from "../services/show"
import { useLocation } from "react-router"
import { setNextShow } from "../store/reducers/general"
import { login, logout } from "../store/reducers/user"
window.saltizId = process.env.NODE_ENV === 'production' ? '677d128cbf1f7bdbaf0c8ef3' : '677d13f4287938e7e7d2e16f'

export function DataFetch() {
    const dispatch = useDispatch()
    const location = useLocation()
    const user = useSelector((state) => state.user)
    const { nextShow } = useSelector((state) => state.general)
    const interval = useRef()

    useEffect(() => {
        loadNextShow()
        loadUser()
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 })
    }, [location.pathname])

    useEffect(() => {
        if (nextShow === 'error') interval.current = setInterval(loadNextShow, 10_000)
        else clearInterval(interval.current)
    }, [nextShow])

    const loadUser = async () => {
        try {
            if (!user.token) return
            const loadedUser = await userService.getUser(user.token)
            dispatch(login(loadedUser))
        }
        catch (err) {
            if (err.message.includes('502')) dispatch(logout())
        }
    }

    const loadNextShow = async () => {
        try {
            const loadedShow = await showService.getNextShow()
            if (loadedShow.date === 0) return dispatch(setNextShow('no-shows'))
            dispatch(setNextShow(loadedShow))
        }
        catch (err) {
            dispatch(setNextShow('error'))
        }
    }

    return null
}
