import { formatDateHour } from '../services/utils'
import { useNavigate } from 'react-router'

export function ShowCard({ show }) {
    const navigate = useNavigate()
    return <div className="show-card">
        <img src={show.imgPc} alt='show' />
        <h1 className='title'>{show.title}</h1>
        <p>{show.ticketsAmount} צופים בתאריך {formatDateHour(show.date)}</p>
        <button onClick={() => navigate('/tickets')}>מידע נוסף</button>
    </div>
}