import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setUpperPopup } from "../store/reducers/general"

export function UpperPopup() {
    const dispatch = useDispatch()
    const { upperPopup } = useSelector((storeState) => storeState.general)

    useEffect(() => {
        if (upperPopup) reset()
    }, [upperPopup])

    const reset = () => {
        setTimeout(() => { dispatch(setUpperPopup('')) }, 4000)
    }

    if (!upperPopup) return <></>

    return <section className='upper-popup'>
        {upperPopup === 'imageSize' && <p>גודל התמונה יכול להיות עד 10MB</p>}
        {upperPopup === 'invalidPhone' && <p>מספר הטלפון אינו תקין</p>}
        {upperPopup === 'copied' && <p>לינק הועתק</p>}
        {upperPopup === 'creator-banned-you' && <p>יוצר התוכן הזה חסם אותך ולכן לא תוכל לקנות כרטיסים לאירועים שלו.</p>}
        {upperPopup === 'empty-nickname' && <p>אנא הקלד שם בעל 2 אותיות לפחות</p>}
        {upperPopup === 'daily-limit' && <p>הגעת למגבלה היומית עבור פעולה זו, אנא נסה שוב מחר.</p>}
        {upperPopup === 'errorCode' && <p>הקוד אינו נכון</p>}
        {upperPopup === 'name-short' && <p>השם שהקלדת קצר מדי, השתמש לפחות ב3 תווים.</p>}
        {upperPopup === 'too-long-300' && <p>הטסקט ארוך מדי, ניתן לכתוב עד 300 תווים.</p>}
        {upperPopup === 'error-server' && <p>משהו השתבש, אנא נסה שנית במועד מאוחר יותר</p>}
        {upperPopup === 'logout' && <p>התנתקת בהצלחה</p>}
        {upperPopup === 'image-inaproppriate' && <p>התמונה אינה עוברת את המסננים שלנו, נסה להעלות תמונה נאותה.</p>}
        {upperPopup === 'fill-name' && <p>אנא מלא את השם שלך</p>}
        {upperPopup === 'subscription-cancelled' && <p>המנוי בוטל</p>}
        {upperPopup === 'email-sent' && <p>המייל נשלח בהצלחה</p>}
        {upperPopup === 'reported-user' && <p>חסמת ודיוות על משתמש זה, לא תראה הודעות ממנו יותר</p>}
        {upperPopup === 'blocked-user' && <p>חסמת משתמש זה, לא תראה הודעות ממנו יותר.</p>}
        {upperPopup === 'missing-details' && <p>אנא מלאו את כל הפרטים</p>}
        {upperPopup === 'max-interests' && <p>ניתן למלא עד 5 תחביבים</p>}
        {upperPopup === 'email-registered' && <p>קיבלנו! ניצור קשר בהמשך.</p>}
        {upperPopup === 'alreadyPurchased' && <p>כבר רכשת כרטיס להופעה הזו</p>}
        {upperPopup === 'warning-message-rate' && <p>ניתן לשלוח הודעה כל 10 שניות.</p>}
        {upperPopup === 'warning-message-rate-60' && <p>ניתן לשלוח הודעה כל 60 שניות.</p>}
        {upperPopup === 'wait-before-otp' && <p>ניתן לקבל קוד חדש פעם בדקה.</p>}
        {upperPopup === 'too-many-otp' && <p>עברת את כמות הקודים שניתן לקבל ביום, אנא נסה שוב מחר.</p>}
        {upperPopup === 'error-code' && <p>הקוד שגוי</p>}
    </section>
}