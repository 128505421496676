import { useState, useRef } from "react"
import { useNavigate } from "react-router"
import { useDispatch } from "react-redux"
import { userService } from "../services/user"
import { logout } from "../store/reducers/user"
import { setUpperPopup } from "../store/reducers/general"
import { Error } from "../cmps/error"
import { Wallpaper } from "../cmps/wallpaper"

export function DeleteAccount() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [phone, setPhone] = useState(null)
    const codeRef = useRef()
    const phoneRef = useRef()

    const handlePhone = async (ev) => {
        if (ev) ev.preventDefault()
        const ph = phoneRef.current.value
        if (ph.length < 9 || ph.length > 11) return dispatch(setUpperPopup('invalidPhone'))
        const formatted = '972' + (ph[0] === '0' ? ph.slice(1) : ph)
        try {
            await userService.sendOtp(Number(formatted))
            setPhone(formatted)
        }
        catch {
            dispatch(setUpperPopup('error-server'))
        }
    }

    const submitCode = async (ev) => {
        if (ev) ev.preventDefault()
        try {
            await userService.deleteUser(phone, codeRef.current.value)
            dispatch(logout())
            navigate('/')
        }
        catch {
            dispatch(setUpperPopup('errorServer'))
        }
    }

    try {
        return <section className='inner-layout welcome'>
            <Wallpaper />
            <img src={require('../style/imgs/logo.webp')} className='logo-middle' accessibilityLabel='לוגו' />
            <div className="inner-small login" style={{ height: '517px' }}>
                {!phone && <>
                    <h1>מחק חשבון</h1>
                    <form onSubmit={handlePhone}>
                        <div>
                            <p className="instructions">אנחנו מצטערים לראות אותך עוזב. על מנת להשלית את התהליך נדרש לאמת את החשבון, אנא הזן את מספר הטלפון איתו אתה רשום.</p>
                            <input type='number' ref={phoneRef} placeholder='...05' required />
                        </div>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-start', flexDirection: 'column' }}>
                            <button className="button">שלח קוד</button>
                        </div>
                    </form>
                </>}
                {phone && <>
                    <h1>מחק חשבון</h1>
                    <form onSubmit={submitCode}>
                        <div>
                            <p className="instructions">שלחנו קוד למספר : <span style={{ opacity: '1' }}>{'0' + phone.slice(3)}</span></p>
                            <input type='number' ref={codeRef} required placeholder="הקלד את הקוד כאן" />
                            <p onClick={() => setPhone(null)} className="wrong">טעיתי במספר</p>
                        </div>

                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-start', flexDirection: 'column' }}>
                            <button className="button red-background">מחק חשבון לצמיתות</button>
                        </div>
                    </form>
                </>}
            </div>
        </section>
    }
    catch {
        return <Error />
    }
}