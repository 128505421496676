import { months, getTimeOfDay, formatHour } from '../services/utils.js'

export function DateTime({ date, color = 'white' }) {
    const dayInMonth = date.getDate()
    const year = date.getFullYear()
    const month = months[date.getMonth() + 1]['he']

    return <div className='date-time'>
        <div className='center-horizontal'>
            <img style={{ marginBottom: '4px' }} src={color === 'main' ? require('../style/imgs/date.webp') : require('../style/imgs/date-white.webp')} />
            <p className={color}>{dayInMonth + ' ' + month + ', ' + year}</p>
        </div>
        <div className='center-horizontal'>
            <img src={color === 'main' ? require('../style/imgs/clock.webp') : require('../style/imgs/clock-white.webp')} />
            <p className={color}>{formatHour(date) + ' ' + (getTimeOfDay(date, 'he'))}</p>
        </div>
    </div>
}