import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setPopup, setUpperPopup } from '../store/reducers/general'
import { TextInput } from '../cmps/chat/text-input.jsx'
import { Banned } from '../cmps/banned.jsx'
import { Welcome } from '../cmps/welcome'
import { Loader } from '../cmps/loader'
import { Error } from '../cmps/error.jsx'
import { userService } from '../services/user.js'

export function AI() {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const [person, setPerson] = useState('danny')
    const [history, setHistory] = useState('loading')
    const [temp, setTemp] = useState('')
    const scrollRef = useRef()

    useEffect(() => {
        loadHistory()
    }, [])

    const loadHistory = async () => {
        try {
            const h = await userService.getQuestionsHistory()
            setHistory(h)
        }
        catch (err) {
            console.log(err)
            setHistory('error')
        }
    }

    const sendQuestion = async (input) => {
        try {
            if (!input) return
            const i = input
            const p = person
            setTemp(i)
            if (scrollRef.current) setTimeout(() => scrollRef.current.scrollTop = scrollRef.current.scrollHeight, 200)
            const ans = await userService.askQuestion(i, p, user.token)
            setTemp('')
            let h = history[p]
            if (h.length >= 5) h.shift()
            h.push({ question: i, answer: ans })
            setHistory({ ...history, [p]: h })
            if (scrollRef.current) setTimeout(() => scrollRef.current.scrollTop = scrollRef.current.scrollHeight, 200)
        }
        catch (err) {
            console.log(err)
            setTemp('')
            if (err?.message?.includes('406')) dispatch(setUpperPopup('daily-limit'))
            else if (err?.message?.includes('408')) dispatch(setUpperPopup('clean-lan'))
            else if (err?.message?.includes('409')) dispatch(setUpperPopup('text-too-long'))
            else dispatch(setUpperPopup('error-server'))
        }
    }

    const renderChat = () => {
        if (history === 'error') return <Error reload={loadHistory} />
        if (history === 'loading') return <Loader type='center' />
        if (history[person]?.length === 0 && !temp) return <></>
        if (history[person]?.length === 0 && temp) return <div>
            <p>{temp}</p>
            <Loader type='center' />
        </div>
        return <>
            {history[person]?.map((q, i) => <div key={i}>
                <p className='question'>{q.question}</p>
                {q.answer ? <p className='answer'>{q.answer}</p> : <Loader type='center' />}
            </div>)}
            {temp && <div>
                <p>{temp}</p>
                <Loader type='center' />
            </div>}
        </>
    }

    if (!user.id) return <Welcome />

    if (user.banned) return <Banned />

    return <div className='inner-layout' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
        <div className='model-menu'>
            <p className={person === 'danny' ? 'selected' : ''} onClick={() => setPerson('danny')}>דני</p>
            <p className={person === 'shoval' ? 'selected' : ''} onClick={() => setPerson('shoval')}>שובל</p>
            <p className={person === 'ori' ? 'selected' : ''} onClick={() => setPerson('ori')}>אורי</p>
            <p className={person === 'paz' ? 'selected' : ''} onClick={() => setPerson('paz')}>פז</p>
            <p className={person === 'nave' ? 'selected' : ''} onClick={() => setPerson('nave')}>נוה</p>
        </div>
        <div className='chat'>
            <div className='messages-container' ref={scrollRef}>
                {renderChat()}
            </div>
            <TextInput onAction={sendQuestion} maxLength={500} />
        </div>
    </div>
}