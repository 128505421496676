export function Pinned({ pinned }) {
    const { text, nickName, color, userId } = pinned

    if (!text) return null

    return <div className='pinned'>
        <img className='pin' src={require('../../style/imgs/pin.webp')} />
        {userId === window.saltizId ? <div className='saltiz'>
            <p style={{ color }}>סולטיז</p>
            <img className='verified' src={require('../../style/imgs/verified-white.png')} />
        </div> : <p style={{ color }}>{nickName}</p>}   
        <p style={{ flex:1,marginRight:'5px' }}>{text}</p>
    </div>

}