export function PrivacyPolicy(){
    return <div className="home privacy-policy">
<h1>Privacy Policy</h1>
    <p>Last updated: July 28, 2024</p>
    <p>This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
    <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>

    <h1>Interpretation and Definitions</h1>
    
    <h3>Interpretation</h3>
    <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

    <h3>Definitions</h3>
    <p>For the purposes of this Privacy Policy:</p>
    <ul>
        <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
        <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party.</li>
        <li><strong>Application</strong> refers to pikmeTV, the software program provided by the Company.</li>
        <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to 516565504, gisin 78 petah tikva.</li>
        <li><strong>Country</strong> refers to: Israel.</li>
        <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone, or a digital tablet.</li>
        <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
        <li><strong>Service</strong> refers to the Application.</li>
        <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company.</li>
        <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself.</li>
        <li><strong>You</strong> means the individual accessing or using the Service.</li>
    </ul>

    <h1>Collecting and Using Your Personal Data</h1>
    
    <h3>Types of Data Collected</h3>
    <h3>Personal Data</h3>
    <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information, including but not limited to:</p>
    <ul>
        <li>Phone number</li>
        <li>Usage Data</li>
    </ul>

    <h3>Usage Data</h3>
    <p>Usage Data is collected automatically when using the Service.</p>
    <p>It may include details such as Your Device's IP address, browser type, time spent on pages, and unique device identifiers.</p>

    <h3>Information Collected while Using the Application</h3>
    <p>We may collect, with Your permission:</p>
    <ul>
        <li>Pictures and other information from Your Device's camera and photo library.</li>
    </ul>

    <h1>Use of Your Personal Data</h1>
    <p>The Company may use Personal Data for various purposes, including:</p>
    <ul>
        <li>To provide and maintain our Service.</li>
        <li>To manage Your Account.</li>
        <li>To contact You regarding updates and communications.</li>
        <li>To provide You with news and offers.</li>
        <li>To manage Your requests.</li>
    </ul>

    <h1>Retention of Your Personal Data</h1>
    <p>The Company will retain Your Personal Data only as long as necessary for the purposes set out in this Privacy Policy.</p>

    <h1>Transfer of Your Personal Data</h1>
    <p>Your information may be transferred to — and maintained on — computers outside of Your jurisdiction.</p>

    <h1>Delete Your Personal Data</h1>
    <p>You have the right to request deletion of Your Personal Data.</p>

    <h1>Disclosure of Your Personal Data</h1>
    <h3>Business Transactions</h3>
    <p>If the Company is involved in a merger or sale, Your Personal Data may be transferred.</p>

    <h3>Law Enforcement</h3>
    <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law.</p>

    <h1>Security of Your Personal Data</h1>
    <p>We strive to use commercially acceptable means to protect Your Personal Data, but no method is 100% secure.</p>

    <h1>Links to Other Websites</h1>
    <p>Our Service may contain links to third-party websites. We are not responsible for their content or privacy policies.</p>

    <h1>Changes to This Privacy Policy</h1>
    <p>We may update Our Privacy Policy from time to time. Changes will be posted on this page.</p>

    <h1>Contact Us</h1>
    <p>If you have any questions about this Privacy Policy, You can contact us:</p>
    <p>By email: admin@homericgames.com</p>
    </div>
}