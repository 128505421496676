import { createSlice } from "@reduxjs/toolkit"

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: '',
    nickName: '',
    phone: '',
    email: '',
    image: '',
    didBoarding: false,
    banned: false,
    purchases: {},
    following: [],
    personal: null,
    notifications: {},
    subscriptions: {},
    blockList: {},
  },
  reducers: {
    setNickName: (state, action) => {
      state.nickName = action.payload
    },
    setImage: (state, action) => {
      state.image = action.payload
    },
    setPhone: (state, action) => {
      state.phone = action.payload
    },
    addFollow: (state, action) => {
      const f = state.following
      f.push(action.payload)
      state.following = f
    },
    addToBlockList: (state, action) => {
      if (!state.blockList) state.blockList = {}
      const b = state.blockList
      b[action.payload] = true
      state.blockList = b
    },
    removeFromBlockList: (state, action) => {
      if (!state.blockList) state.blockList = {}
      const b = state.blockList
      delete b[action.payload]
      state.blockList = b
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload
    },
    setBanned: (state, action) => {
      state.banned = action.payload
    },
    addSubscription: (state, action) => {
      const s = state.subscriptions
      s[action.payload] = true
      state.subscriptions = s
    },
    removeSubscription: (state, action) => {
      const s = state.subscriptions
      delete s[action.payload]
      state.subscriptions = s
    },
    completeBoarding: (state) => {
      state.didBoarding = true
    },
    removeFollow: (state, action) => {
      state.following = state.following.filter(f => f !== action.payload)
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
    addPurchase: (state, action) => {
      state.purchases[action.payload.id] = action.payload
    },
    setPersonal: (state, action) => {
      state.personal = action.payload
    },
    login: (state, action) => {
      state.id = action.payload._id
      state.nickName = action.payload.nickName
      state.phone = action.payload.phone
      state.image = action.payload.image
      state.didBoarding = action.payload.didBoarding
      state.banned = action.payload.banned
      state.purchases = action.payload.purchases
      state.personal = action.payload.personal
      state.following = action.payload.following
      state.notifications = action.payload.notifications || {}
      state.subscriptions = action.payload.subscriptions || {}
      state.blockList = action.payload.blockList || {}
    },
    logout: (state) => {
      document.cookie = "cookieName=auth; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
      state.id = ''
      state.nickName = ''
      state.phone = ''
      state.email = ''
      state.image = ''
      state.purchases = {}
      state.following = []
      state.personal = null
      state.banned = false
      state.notifications = {}
      state.didBoarding = false
      state.subscriptions = {}
      state.blockList = {}
    },
  },
})

export const {
  setNickName,
  setPhone,
  setEmail,
  addToBlockList,
  setImage,
  addSubscription,
  removeSubscription,
  removeFromBlockList,
  addPurchase,
  addFollow,
  completeBoarding,
  setNotifications,
  removeFollow,
  setPersonal,
  login,
  logout,
} = userSlice.actions

export default userSlice.reducer
