
import { useState, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from 'react-router'
import { userService } from '../services/user'
import { setNickName, setImage, logout } from '../store/reducers/user'
import { Welcome } from '../cmps/welcome'
import { Banned } from '../cmps/banned'
import { Error } from '../cmps/error'
import { setPopup } from '../store/reducers/general'
import { useQuery } from '@tanstack/react-query'
import { setUpperPopup } from '../store/reducers/general'
import { Loader } from '../cmps/loader'
import { ShowCard } from '../cmps/show-card'
import { Wallpaper } from '../cmps/wallpaper'
import { httpService } from '../services/http'

export function Profile() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((state) => state.user)
  const { nextShow } = useSelector(state => state.general)
  const [nameEdit, setNameEdit] = useState(false)
  const [loader, setLoader] = useState(false)
  const [displayedTickets, setDisplayedTickets] = useState(2)
  const nameRef = useRef()
  const { data, isLoading, error, refetch } = useQuery({ queryKey: ['tickets', Object.keys(user?.purchases || {}).length], queryFn: loadTickets, staleTime: 30 * 60 * 1000 })

  async function loadTickets() {
    let loadedPurchases = await userService.getUserUpcoming()
    loadedPurchases.sort((a, b) => new Date(b.date) > new Date(a.date) ? 1 : -1)
    return loadedPurchases
  }

  const imgChange = async (ev) => {
    try {
      if (ev.target.files[0].size > 1000000) dispatch(setPopup('image-size'))
      setLoader(true)
      const uploadedImage = await httpService.upload(ev.target.files[0])
      const updatedUser = await userService.updateUser({ image: uploadedImage })
      dispatch(setImage(updatedUser.image))
      setLoader(false)
    }
    catch (err) {
      setLoader(false)
      if (err.message.includes('409')) dispatch(setUpperPopup('image-inaproppriate'))
      else if (err.message?.includes('406')) dispatch(setUpperPopup('daily-limit'))
      else dispatch(setUpperPopup('error-server'))
    }
  }

  const nickNameChange = async (ev) => {
    try {
      ev.preventDefault()
      if (nameRef.current.value.length < 3) return dispatch(setUpperPopup('name-short'))
      await userService.updateUser({ nickName: nameRef.current.value })
      dispatch(setNickName(nameRef.current.value))
      setNameEdit(false)
    }
    catch {
      setNameEdit(false)
      dispatch(setUpperPopup('error-server'))
    }
  }

  const renderTickets = () => {
    if (error) return <Error fullScreen={false} reload={refetch} />
    if (isLoading) return <div className='no-tickets'>
      <Loader type='center' />
    </div>
    if (!data.length) return <div className="no-tickets">
      <img src={require('../style/imgs/header/tickets-full.webp')} alt="אין כרטיסים" />
      <p>לא נמצאו כרטיסים בבעלותך.</p>
      {typeof nextShow === 'object' ? <div className='to-tickets' onClick={() => navigate('/tickets')}>לכרטיסים</div> : <p>אין הופעות בקרוב.</p>}
    </div>

    return <>
      <div className='cards-wrapper'>
        {data.slice(0, displayedTickets).map(purchase => <ShowCard key={purchase._id} show={purchase} />)}
      </div>
      {data.length > displayedTickets && <div className='show-more-tickets' onClick={() => setDisplayedTickets((prev) => prev + 2)}>
        <p>הצג הופעות בעתיד הרחוק יותר</p>
        <span className="material-symbols-outlined">keyboard_arrow_down</span>
      </div>}
    </>
  }

  if (error) <Error reload={refetch} />

  if (!user.id) return <Welcome />

  if (user.banned) return <Banned />

  try {
    return <section className='profile'>

      <Wallpaper />

      <section className='img-holder'>
        <img className='profile-banner' src={require('../style/imgs/profile-banner.webp')} alt='profile banner' />
      </section>

      <div className='profile-wrapper narrow-layout'>
        <div className='editable'>
          <img className='user-img noselect' src={user.image} alt='user image profile' />
          <div className='user-img img-cover noselect'>
            <input accept="image/*" type="file" id="img" onChange={imgChange} />
            <label htmlFor='img'><span className="material-symbols-outlined clickable">edit</span></label>
          </div>
          {loader && <div className='user-img img-cover noselect' style={{ opacity: 1 }}>
            <Loader type='center' />
          </div>}
          <section className='details'>
            {nameEdit ? <form onSubmit={nickNameChange}>
              <div onClick={() => setNameEdit(false)}><span className='material-symbols-outlined clickable' style={{ fontSize: '20px' }}>close</span></div>
              <input autoFocus maxLength="12" type='text' placeholder='הכנס שם חדש' ref={nameRef} />
              <button><span className="material-symbols-outlined mirror">chevron_right</span></button>
            </form> :
              <div className='name-holder rtl '>
                <h1>{' ' + user.nickName + ' '}<span onClick={() => setNameEdit(!nameEdit)} className="material-symbols-outlined clickable noselect" style={{ fontSize: '20px' }}>edit</span></h1>
              </div>}
          </section>
        </div>
        <h3>הכרטיסים שלי</h3>
        {renderTickets()}
        <h3>משפטי</h3>
        <Item text='תנאי שימוש' onClick={() => window.open('https://www.privacypolicyonline.com/live.php?token=BSvr42ubTJcEKnEu7WdkM3qMJ7Dozlla', '_blank').focus()} />
        <Item text='מדיניות פרטיות' onClick={() => navigate('/privacy-policy')} />
        <h3>החשבון שלי</h3>
        <Item text='משתמשים שחסמתי' onClick={() => dispatch(setPopup('block-list'))} />
        <Item text='התנתק' onClick={() => dispatch(logout())} />
        <Item text='מחק חשבון' onClick={() => navigate('/delete-account')} red='red' />
      </div>
    </section>
  }
  catch {
    return <Error />
  }
}

function Item({ text, onClick, red = false }) {
  const className = red ? 'list-item red' : 'list-item'
  return <div className={className} onClick={onClick}>
    <p>{text}</p>
    <span className="material-symbols-outlined mirror">chevron_right</span>
  </div>
}