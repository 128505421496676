import Axios from 'axios'

const BASE_URL = process.env.NODE_ENV === 'production'
    ? 'https://server.pikme.tv/'
    // : 'https://server.pikme.tv/'
    : 'http://localhost:3029/'

var axios = Axios.create({
    withCredentials: true
})

async function upload(file) {
    const formData = new FormData()
    formData.append('image', file)
    const res = await axios({
        url: `${BASE_URL}handle-user/upload-profile-image`,
        method: 'POST',
        data: formData
    })
    return res.data
}

export const httpService = {
    get(endpoint, data) {
        return ajax(endpoint, 'GET', data)
    },
    post(endpoint, data) {
        return ajax(endpoint, 'POST', data)
    },
    put(endpoint, data) {
        return ajax(endpoint, 'PUT', data)
    },
    delete(endpoint, data) {
        return ajax(endpoint, 'DELETE', data)
    },
    upload
}

async function ajax(endpoint, method = 'GET', data = null) {
    try {
        const res = await axios({
            url: `${BASE_URL}${endpoint}`,
            method,
            data,
            params: (method === 'GET') ? data : null
        })
        return res.data
    }
    catch (err) {
        throw err
    }
}