import { useRef } from "react"
import { useDispatch } from "react-redux"
import { setUpperPopup } from "../../store/reducers/general"

export function TextInput({ onAction, maxLength = 300 }) {
    const dispatch = useDispatch()
    const inputRef = useRef()

    const onSend = (ev) => {
        ev.preventDefault()
        const text = inputRef.current.value
        if (!text) return
        if (text.length > maxLength) return dispatch(setUpperPopup(`too-long-${maxLength}`))
        inputRef.current.value = ''
        onAction(text)
    }

    return <form className="input-container" onSubmit={onSend}>
        <input maxLength={maxLength} type="text" placeholder="הקלד" autoComplete="off" ref={inputRef}/>
        <span className="material-symbols-outlined mirror" onClick={onSend}>send</span>
    </form>
}
