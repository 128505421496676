export function Header() {
    return <div className='chat-header'>
        <img className='saltiz-image' src={require('../../style/imgs/saltiz.webp')} />
        <div>
            <div className='line'>
                <p>סולטיז</p>
                <img className='verified' src={require('../../style/imgs/verified.webp')} />
            </div>
            <p>כאן חברי הקהילה יכולים לדבר אחד עם השני.</p>
        </div>
    </div>
}