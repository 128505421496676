import { useEffect, useState } from "react"
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

//cmps
import { Header } from './cmps/header'
import { Footer } from './cmps/footer'
import { Menu } from "../src/cmps/menu"
import { Popup } from "./cmps/popup/popup"
import { UpperPopup } from './cmps/upper-popup'
import { Accessibility } from "./pages/accessibility"
import { AccessibilityWidget } from "./cmps/accessibility-widget"
import { Loader } from "./cmps/loader"
import { DataFetch } from "./cmps/data-fetch"

function App() {
  const [pages, setPages] = useState(null)

  useEffect(() => {
    loadPages()
  }, [])

  const loadPages = () => {
    const { Home } = require('./pages/home')
    const { Profile } = require('./pages/profile')
    const { Tickets } = require('./pages/tickets')
    const { Login } = require('./pages/login')
    const { Boarding } = require('./pages/boarding')
    const { DeleteAccount } = require('./pages/delete-account')
    const { Stream } = require('./pages/stream')
    const { PrivacyPolicy } = require('./pages/privacy-policy')
    const { Chat } = require('./pages/chat')
    const { Download } = require('./pages/download')
    const { AI } = require('./pages/ai')
    setPages({ Home, Profile, Tickets, Login, DeleteAccount, PrivacyPolicy, Chat, Download, Boarding, Stream, AI })
  }

  if (!pages) return <Loader type={'full'} />

  if (isMobile) return <Router>
    <Routes>
      <Route path='/delete-account' element={<pages.DeleteAccount />} />
      <Route path='/privacy-policy' element={<pages.PrivacyPolicy />} />
      <Route path='/' element={<pages.Download />} />
    </Routes>
  </Router>

  return <Router>
    <DataFetch />
    <main className='main-layout' id='main-layout'>
      <Header />
      <Routes>
        <Route path='/profile' element={<pages.Profile />} />
        <Route path='/tickets' element={<pages.Tickets />} />
        <Route path='/accessibility' element={<Accessibility />} />
        <Route path='/boarding' element={<pages.Boarding />} />
        <Route path='/login' element={<pages.Login />} />
        <Route path='/stream' element={<pages.Stream />} />
        <Route path='/chat' element={<pages.Chat />} />
        <Route path='/ai' element={<pages.AI />} />
        <Route path='/privacy-policy' element={<pages.PrivacyPolicy />} />
        <Route path='/delete-account' element={<pages.DeleteAccount />} />
        <Route path='/download' element={<pages.Download />} />
        <Route path='/' element={<pages.Home />} />
      </Routes>
    </main>
    <Footer />
    <Menu />
    <Popup />
    <UpperPopup />
    <AccessibilityWidget />
  </Router>
}

export default App