import { useSelector } from 'react-redux'
import { userService } from '../services/user'
import { Error } from '../cmps/error'
import { Loader } from '../cmps/loader'
import { Social } from '../cmps/creator/social'
import { Banned } from '../cmps/banned'
import { Welcome } from '../cmps/welcome'
import { useQuery } from '@tanstack/react-query'
import { Feed } from '../cmps/creator/feed'
import { Wallpaper } from '../cmps/wallpaper'
import { ShowCard } from '../cmps/show-card'

export function Home() {
    const user = useSelector(state => state.user)
    const { nextShow } = useSelector(state => state.general)
    const { data: creator, isLoading, error, refetch } = useQuery({ queryKey: ['soltiz'], queryFn: loadCreator, staleTime: 120 * 60 * 1000 })

    async function loadCreator() {
        const loadedCreator = await userService.getSoltiz(user.token)
        return loadedCreator
    }

    if (!user.nickName) return <Welcome />

    if (user.banned) return <Banned />

    if (error) return <Error reload={refetch} />

    if (isLoading) return <Loader type='full' />

    try {
        return <div className='home'>
            <Wallpaper />
            <img src={creator.banner} className='creator-banner' />
            <div className='inner-home narrow-layout'>
                <Social creator={creator} />
                {(typeof nextShow === 'object' && nextShow.date > Date.now() - (3 * 60 * 60 * 1000) && !user.purchases[nextShow._id]) && <ShowCard show={nextShow} />}
                <Feed creatorId={creator._id} />
            </div>
        </div>
    }
    catch (err) {
        return <Error reload={loadCreator} />
    }
}