import React from 'react'
export const BroadMessage = React.memo(({ item, onClick }) => {

    const formatMessageDate = (date = '1/8/2025 17:54') => {
        if (!date) return ''
        const [datePart, timePart] = date.split(" ")
        const [day, month, year] = datePart.split("/").map(Number)
        const messageDate = new Date(year, month - 1, day)

        const today = new Date()
        today.setHours(0, 0, 0, 0)

        const yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)

        if (messageDate.getTime() === today.getTime()) return timePart
        if (messageDate.getTime() === yesterday.getTime()) return `אתמול ${timePart}`
        return date
    }

    const formatted = formatMessageDate(item.date)

    return <>
        <div className='broad-message' onClick={() => onClick(item)}>
            <img className='user-img' src={item.image} />
            <div className='inner'>
                <div className='line'>
                    {item.verified ? <div className='saltiz'>
                        <p style={{ color: item.color }}>{item.nickName}</p>
                        <img className='verified' src={require('../../style/imgs/verified-white.png')} />
                    </div> :
                        <p style={{ color: item.color }}>{item.nickName}</p>}
                    <p>•</p>
                    <p className='mini'>{formatted}</p>
                </div>
                <p>{item.text}</p>
            </div>
        </div>
        {item.attachedImage && <div className="image-wrapper">
            <img src={item.attachedImage} className="attached-image" />
        </div>}
    </>
})