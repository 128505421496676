import { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setPopupPay, setPopup, setUpperPopup } from '../../store/reducers/general'
import { userService } from '../../services/user'

export function PopupSupport() {
    const dispatch = useDispatch()
    const { popupPay } = useSelector((storeState) => storeState.general)
    const subject = useRef()
    const description = useRef()
    const email = useRef()
    const name = useRef()

    useEffect(() => {
        return () => {
            dispatch(setPopupPay(''))
        }
    }, [])

    const sendMail = async (e) => {
        try {
            e.preventDefault()
            await userService.sendEmail({ subject: subject.current.value, description: description.current.value, email: email.current.value, name: name.current.value })
            dispatch(setPopupPay(''))
            dispatch(setPopup(''))
            dispatch(setUpperPopup('email-sent'))
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    return <form className="popup-support" onSubmit={sendMail}>
        <h1>איך נוכל לעזור?</h1>
        <p>בעת מילוי הטופס, ישלח מייל לצווות התמיכה שלנו. אנא פרט ככל הניתן על הבעיה.</p>
        <input placeholder="שם מלא" ref={name} required maxLength={30}/>
        <input placeholder="מייל" ref={email} required type='email' maxLength={50}/>
        <input placeholder="באיזה נושא הבעיה?" maxLength={100} ref={subject} required readOnly={popupPay ? true : false} value={popupPay ? `בקשר לאירוע ${popupPay.title} של ${popupPay.creatorName}` : ''} />
        <textarea placeholder="תיאור הבעיה" ref={description} required maxLength={400}/>
        <button>שלח</button>
    </form>
}