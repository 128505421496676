import { useNavigate } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { toggleMenu } from '../store/reducers/general'

export function Header() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const user = useSelector((state) => state.user)

    const onLogoClick = () => {
        if (location.pathname === '/') window.location.reload()
        else navigate('/')
    }

    return <nav className='header noselect' id='header'>
        <img id="logo" alt='pikmetv logo' onClick={onLogoClick} className="logo" src={require('../style/imgs/logo.webp')} />

        <div className="header-options-bar">
            {user.nickName && <>
                {location.pathname === '/tickets' ? <img onClick={() => navigate('/tickets')} src={require('../style/imgs/header/tickets-full.webp')} /> : <img onClick={() => navigate('/tickets')} src={require('../style/imgs/header/tickets.webp')} />}
                {location.pathname === '/chat' ? <img onClick={() => navigate('/chat')} src={require('../style/imgs/header/chat-full.webp')} /> : <img onClick={() => navigate('/chat')} src={require('../style/imgs/header/chat.webp')} />}
                <img onClick={() => navigate('/profile')} src={user.image} className={`header-user-img ${location.pathname === '/profile' ? 'border-main' : ''}`} />
            </>}
            <span className="material-symbols-outlined menu-icon clickable hover-main" onClick={() => { dispatch(toggleMenu()) }} tabIndex={0}>menu</span>
        </div>
    </nav>
}