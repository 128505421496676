import { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { setAnimations } from '../store/reducers/general'
import { isMobile } from "react-device-detect"
let headers = false
let clickables = false
let zoom = 0

export function AccessibilityWidget() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [popup, setPopup] = useState(false)
    const popupRef = useRef(null)
    const acRef = useRef(null)

    useEffect(() => {
        if (popup) document.addEventListener('click', handleClickOutside)
        else document.removeEventListener('click', handleClickOutside)
    }, [popup])

    const handleClickOutside = (event) => {
        if (popup && popupRef.current && !popupRef.current.contains(event.target) && (!acRef.current || !acRef.current.contains(event.target))) setPopup(false)
    }


    const toggleBlackWhite = () => {
        if (document.documentElement.style.filter === 'grayscale(100%)') document.documentElement.style.filter = ''
        else document.documentElement.style.filter = 'grayscale(100%)'
    }

    const toggleInvert = () => {
        if (document.documentElement.style.filter === 'contrast(1.5)') document.documentElement.style.filter = ''
        else document.documentElement.style.filter = 'contrast(1.5)'
    }

    const adjustZoom = (val) => {
        if ((zoom <= (isMobile ? -2 : -3) && val < 0) || (zoom >= (isMobile ? 2 : 3) && val > 0)) return
        document.documentElement.style.zoom = (Number(document.documentElement.style.zoom) || 1) + val
        zoom += (val * 5)
    }

    const boldenHeadings = () => {
        if (!headers) {
            var headings = document.querySelectorAll('h1, h2, h3, h4, h5, h6')
            headings.forEach(function (heading) {
                heading.style.textDecoration = 'underline'
            })
            headers = true
            return
        }

        var headings = document.querySelectorAll('h1, h2, h3, h4, h5, h6')
        headings.forEach(function (heading) {
            heading.style.textDecoration = ''
        })
        headers = false
    }

    const boldenClickables = () => {
        let all = Array.from(document.querySelectorAll('*'))
        const clickabler = all.filter(el => el.onclick || el.tagName.toLowerCase() === 'a')
        if (!clickables) {
            clickabler.forEach(function (link) {
                link.classList.add('outline-bolden')
            })
            clickables = true
            return
        }
        clickabler.forEach(function (link) {
            link.classList.remove('outline-bolden')
        })
        clickables = false
    }

    return (<>
        <img src={require('../style/imgs/accessibility.webp')} role='button' className='ac-img' tabIndex={20} onClick={() => setPopup(!popup)} ref={acRef} onKeyDown={(e)=>{if(e.key==='Enter') setPopup(!popup)}}/>
        {popup && <div className='ac-popup' ref={popupRef}>
            <button onClick={toggleBlackWhite} tabIndex={30}><span className="material-symbols-outlined">contrast_rtl_off</span>מצב שחור לבן </button>
            <button onClick={toggleInvert} tabIndex={30}><span className="material-symbols-outlined">contrast</span>הגבר ניגודיות</button>
            <button tabIndex={30} onClick={() => adjustZoom(0.2)}><span className="material-symbols-outlined">add</span>זום פנימה</button>
            <button tabIndex={30} onClick={() => adjustZoom(-0.2)}><span className="material-symbols-outlined">remove</span>זום החוצה</button>
            <button tabIndex={30} onClick={boldenHeadings}><span className="material-symbols-outlined">match_word</span>הדגש כותרות</button>
            <button tabIndex={30} onClick={boldenClickables}><span className="material-symbols-outlined">format_underlined</span>הדגש לחצנים</button>
            <button tabIndex={30} onClick={() => dispatch(setAnimations())}><span className="material-symbols-outlined">animation</span>בטל אנימציות</button>
            <button tabIndex={30} onClick={() => window.location.reload()}><span className="material-symbols-outlined">restart_alt</span>רענון</button>
            <p onClick={() => setPopup(false)} tabIndex={30} role='button'>סגור</p>
            <p onClick={()=>{setPopup(false);navigate('/accessibility')}} tabIndex={30} role='button'>הצהרת נגישות</p>
        </div>}
    </>)
}