import { httpService } from './http.js'

export const userService = {
    validateOtp,
    sendOtp,
    updateUser,
    getUserPurchases,
    getFeed,
    follow,
    unfollow,
    sendEmail,
    blockUser,
    checkBeforePurchase,
    getUserUpcoming,
    reportUser,
    getUpdate,
    getQuestionsHistory,
    updateNotifications,
    purchaseTicket,
    submitDetails,
    deleteComment,
    unBlockUser,
    getSoltiz,
    getBlockedUsers,
    getTestUser,
    getSubscriptions,
    askQuestion,
    cancelSubscription,
    purchaseSubscription,
    deleteUser,
    getUser,
    getCreator,
    getCreatorFeed,
    handleLikePost,
    votePoll,
    addComment,
    getReplies,
    getComments,
    reply,
    handleLikeComment,
}

// do not require auth :

async function validateOtp(phone, otp) {
    const res = await httpService.post('handle-user/validate-otp/' + phone, { otp })
    return res
}

async function sendOtp(phone) {
    const confirm = await httpService.get('handle-user/send-otp/' + phone)
    return confirm
}

async function getFeed(creatorsIds, purchasesIds = [], search) {
    const feed = await httpService.post('handle-user/get-feed', { creatorsIds, purchasesIds, search })
    return feed
}

async function sendEmail(details) {
    const res = await httpService.post('handle-user/send-email', details)
    return res
}

async function getTestUser() {
    const user = await httpService.get('handle-user/get-test-user')
    return user
}

async function getUpdate(version) {
    const update = await httpService.get(`handle-user/get-app-updates/${version}`)
    return update
}

// require auth :

async function getCreatorFeed(creatorId, from = 0) {
    const feed = await httpService.get(`handle-user/get-creator-feed/${creatorId}/${from}`)
    return feed
}

async function handleLikePost(creatorId, postId, like) {
    const res = await httpService.post('handle-user/handle-like-post', { creatorId, postId, like })
    return res
}

async function getBlockedUsers() {
    const blockedUsers = await httpService.get('handle-user/get-blocked-users')
    return blockedUsers
}

async function reportUser(report) {
    const res = await httpService.post('handle-user/report-user', report)
    return res
}

async function blockUser(blockedId) {
    const id = await httpService.put('handle-user/block-user', { blockedId })
    return id
}

async function unBlockUser(blockedId) {
    const id = await httpService.put('handle-user/unblock-user', { blockedId })
    return id
}

async function votePoll(postId, option) {
    const res = await httpService.post('handle-user/vote-poll', { postId, option })
    return res
}

async function deleteUser() {
    const res = await httpService.delete('handle-user/delete-user-auth', null)
    return res
}

async function deleteComment(comment) {
    const res = await httpService.delete('handle-user/delete-comment', { comment })
    return res
}

async function getSubscriptions() {
    const subscriptions = await httpService.get('handle-user/get-subscriptions')
    return subscriptions
}

async function cancelSubscription(creatorId) {
    const res = await httpService.post('handle-user/cancel-subscription', { creatorId })
    return res
}

async function purchaseSubscription(creatorId, subscription) {
    const creator = await httpService.post('handle-user/subscribe', { creatorId, subscription })
    return creator
}

async function addComment(comment) {
    const newComment = await httpService.post('handle-user/add-comment', { comment })
    return newComment
}

async function getReplies(commentId, from = 0) {
    const replies = await httpService.get(`handle-user/get-replies/${commentId}/${from}`)
    return replies
}

async function getComments(postId, from = 0) {
    const comments = await httpService.get(`handle-user/get-comments/${postId}/${from}`)
    return comments
}

async function reply(commentId, reply) {
    const newReply = await httpService.post('handle-user/add-reply', { commentId, reply })
    return newReply
}

async function handleLikeComment(commentId, like) {
    const confirm = await httpService.post('handle-user/handle-like-comment', { commentId, like })
    return confirm
}

async function getSoltiz() {
    const soltiz = await httpService.get('handle-user/get-soltiz')
    return soltiz
}

//

async function getUser() {
    const user = await httpService.get('handle-user/get-user')
    return user
}

async function follow(creatorId) {
    const res = await httpService.post('handle-user/follow', { creatorId })
    return res
}

async function updateNotifications(data, allow) {
    const noti = await httpService.post('handle-user/update-notifications', { token: data, allow })
    return noti
}

async function unfollow(creatorId) {
    const res = await httpService.post('handle-user/unfollow', { creatorId })
    return res
}

async function updateUser(update) {
    const updatedUser = await httpService.post('handle-user/update-info', update)
    return updatedUser
}

async function getUserPurchases() {
    const purchases = await httpService.get('handle-user/get-purchases')
    return purchases
}

async function getCreator(id) {
    const creator = await httpService.get('handle-user/get-creator/' + id)
    return creator
}

async function purchaseTicket(showId) {
    const miniShow = await httpService.post('handle-user/make-purchase', { showId })
    return miniShow
}

async function getUserUpcoming() {
    const shows = await httpService.get('handle-user/get-user-upcoming')
    return shows
}

async function submitDetails(details) {
    const updatedUser = await httpService.put('handle-user/submit-details', details)
    return updatedUser
}

async function checkBeforePurchase(creatorId, showId) {
    const canPurchase = await httpService.get(`handle-user/check-before-purchase/${creatorId}/${showId}`)
    return canPurchase
}

// questions :

async function askQuestion(question, person) {
    const ans = await httpService.post('handle-question/ask-question', { question, person }, 100_000)
    return ans
}

async function getQuestionsHistory() {
    const history = await httpService.get('handle-question/history')
    return history
}