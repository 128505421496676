import { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setPopup, setPopupInfo, setUpperPopup } from '../../store/reducers/general'
import { io } from 'socket.io-client'
import { TextInput } from './text-input.jsx'
import { Message } from './message.jsx'
import { Muted } from './muted.jsx'
import { Pinned } from './pinned.jsx'
import { make2digits } from '../../services/utils'
import { Loader } from '../loader'
const backupNickName = "Guest" + Math.floor(Math.random() * 100000)
const colors = ['#FF5733', '#FFD700', '#00FF00', '#FF00FF', '#00FFFF', '#FF6347', '#FFD700', '#32CD32', '#DA70D6', '#87CEEB', '#FF4500', '#FF8C00', '#7CFC00', '#9400D3', '#00FF7F', '#FF1493', '#00BFFF', '#FFA07A', '#FFD700', '#00FF00', '#FF00FF', '#00FFFF', '#FF6347', '#FFD700', '#32CD32', '#DA70D6', '#87CEEB', '#FF4500', '#FF8C00'];
let socket = null

export function Chat({ showId, user, setViewers, miniCreator }) {
    const dispatch = useDispatch()
    const [messages, setMessages] = useState('loading')
    const [canSend, setCanSend] = useState(true)
    const [seconds, setSeconds] = useState(0)
    const [isConnectionActive, setIsConnectionActive] = useState(true)
    const [pinned, setPinned] = useState(null)
    let nickName = user.nickName || backupNickName
    let randomColor = useRef(colors[nickName.charCodeAt(1) % colors.length] || 'white')
    const messagesBuffer = useRef([])
    const scrollRef = useRef()

    useEffect(() => {
        connectSocket()
        const interval = setInterval(() => {
            if (!messagesBuffer.current.length) return
            setMessages((prevMessages) => {
                const updated = [...prevMessages, ...messagesBuffer.current]
                messagesBuffer.current = []
                if (updated.length > 155) return updated.slice(-50)
                return updated
            })
            const toScroll = scrollRef.current.scrollTop + scrollRef.current.clientHeight === scrollRef.current.scrollHeight
            if (toScroll) setTimeout(() => scrollRef.current.scrollTop = scrollRef.current.scrollHeight, 100)
        }, 1500)

        return () => {
            if (socket) {
                if (socket) {
                    socket.off('message')
                    socket.off('message-bulk')
                    socket.off('viewera-update')
                    socket.off('muted')
                    socket.off('update-pinned')
                    socket.off('disconnect')
                    socket.off('connect_error')
                    socket.close()
                    socket = null
                }
            }
            clearInterval(interval)
        }
    }, [])

    const connectSocket = () => {
        if (socket) {
            socket.close()
            socket = null
        }
        socket = process.env.NODE_ENV === 'production' ? io('https://chat.pikme.tv', { transports: ['websocket'] }) : io('http://localhost:3031', { transports: ['websocket'] })

        socket.emit('joinRoom', { userId: user.id, roomId: showId, username:user.nickName })
        socket.on('disconnect', () => setIsConnectionActive(false))
        socket.on('connect_error', () => setIsConnectionActive(false))
        socket.on('message', (message) => messagesBuffer.current.push(message))
        socket.on('message-bulk', (messages) => {
            setMessages(messages)
            setTimeout(() => { if (scrollRef?.current) scrollRef.current.scrollTop = scrollRef.current.scrollHeight }, 300)
        })
        socket.on('update-pinned', (pinned) => setPinned(pinned))
        socket.on('muted', (time) => setSeconds(Math.ceil((time - Date.now()) / 1000)))
        socket.on('wait', () => dispatch(setUpperPopup('warning-message-rate-60')))
        socket.on('change-pinned-text', (text) => setPinned({ ...pinned, text }))
        socket.on('viewera-update', (viewers) => setViewers(viewers))
        setIsConnectionActive(true)
    }

    const sendMessage = (input) => {
        if (input === '') return
        if (input.length > 300) return dispatch(setPopup('warning-message-length'))
        if (!canSend) return dispatch(setUpperPopup('warning-message-rate-60'))
        const date = new Date()
        const formatted = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${make2digits(date.getMinutes())}`
        const newMessage = { text: input, nickName: nickName, color: randomColor.current, roomId: showId, userId: user.id, image: user.image, date: formatted }
        socket.emit('chat', newMessage)
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight
        setCanSend(false)
        setTimeout(() => setCanSend(true), 60_000)
    }

    const showUser = (message) => {
        if (message.userId === user.id || (message.nickName === 'הודעת מערכת' && !message.userId) || message.userId === miniCreator.id) return
        dispatch(setPopupInfo(message))
        dispatch(setPopup('block-user'))
    }

    const renderItem = useCallback((item) => {
        if (user?.blockList[item.userId]) return null
        return <Message item={item} key={item.id} onAction={() => showUser(item)} miniCreator={miniCreator} />
    }, [user.blockList, showUser])

    const renderLower = () => {
        if (!isConnectionActive) return <div onClick={connectSocket} className='reconnect'><p>לחץ להתחברות מחדש</p></div>
        if (seconds > 0) return <Muted initialSeconds={seconds} setOuterSeconds={setSeconds} />
        return <TextInput onAction={sendMessage} />
    }

    return <div className='chat' style={{ width: '340px' }}>
        {pinned && <Pinned pinned={pinned} />}
        <div className='messages-container' ref={scrollRef}>
            {messages === 'loading' ? <Loader type='center' /> : messages.map((item) => renderItem(item))}
        </div>
        {renderLower()}
    </div>
}