export function Social({ creator }) {
    return <div className='social'>
        <img src={creator.image} className='image' />
        <div className='details'>
            <div className='saltiz'>
                <p skip={[0]} >{creator.nickName}</p>
                <img src={require('../../style/imgs/verified.webp')} />
            </div>

            <div className='inner'>
                {creator.socialLinks.youtube && <div className='soc' onClick={() => window.open(creator.socialLinks.youtube, '_blank')}>
                    <img className='socialIcon' src={require('../../style/imgs/social/youtube.webp')} />
                    <p color='main7'>יוטיוב</p>
                </div>}
                {creator.socialLinks.instagram && <div className='soc' onClick={() => window.open(creator.socialLinks.instagram, '_blank')}>
                    <img className='socialIcon' src={require('../../style/imgs/social/instagram.webp')} />
                    <p color='main7'>אינסטגרם</p>
                </div>}
                {creator.socialLinks.tiktok && <div className='soc' onClick={() => window.open(creator.socialLinks.tiktok, '_blank')}>
                    <img className='socialIcon' src={require('../../style/imgs/social/tiktok.webp')} />
                    <p color='main7'>טיקטוק</p>
                </div>}
            </div>
        </div>
    </div>
}