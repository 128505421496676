import { createSlice } from "@reduxjs/toolkit"


export const generalSlice = createSlice({
    name: 'general',
    initialState: {
        nextShow: 'loading',
        streamingShow: null,
        filter: '',
        menu: '',
        upperPopup: '',
        popup: '',
        popupInfo: {},
        chatCache: 'loading',
        popupCallback: null,
        popupPay: '',
        callbackLink: '',
        animations: true,
        seenWarning: false,
        showFooter: true,
        pageScroll: 0,
        animations: true,
    },
    reducers: {
        setNextShow: (state, action) => {
            state.nextShow = action.payload
        },
        setStreamingShow: (state, action) => {
            state.streamingShow = action.payload
        },
        setPopup: (state, action) => {
            state.popup = action.payload
        },
        setUpperPopup: (state, action) => {
            state.upperPopup = action.payload
        },
        setPopupPay: (state, action) => {
            state.popupPay = action.payload
        },
        setShowFooter: (state, action) => {
            state.showFooter = action.payload
        },
        setPoupPay:(state, action) => {
            state.popupPay = action.payload
        },
        setAnimations: (state, action) => {
            state.animations = action.payload
        },
        setCallbackLink: (state, action) => {
            state.callbackLink = action.payload
        },
        setMenu: (state, action) => {
            state.menu = action.payload
        },
        toggleMenu: (state) => {
            state.menu = state.menu === '' ? 'normal' : ''
        },
        setChatCache: (state, action) => {
            state.chatCache = action.payload
        },
        setPopupInfo: (state, action) => {
            state.popupInfo = action.payload
        },
        setPopupCallback: (state, action) => {
            state.popupCallback = action.payload
        },
        setFilter: (state, action) => {
            state.filter = action.payload
        },
        userSawWarning: (state) => {
            state.seenWarning = true
        },
        setAnimations: (state, action) => {
            state.animations = action.payload
        },
    }
})

export const {
    setPopup,
    setUpperPopup,
    setStreamingShow,
    setShowFooter,
    setCallbackLink,
    setLan,
    setMenu,
    setPopupPay,
    setFilter,
    setPopupInfo,
    userSawWarning,
    setChatCache,
    toggleMenu,
    setPageScroll,
    setViewers,
    setAnimations,
    setPopupUpdate,
    setNextShow,
} = generalSlice.actions

export default generalSlice.reducer
