import React from 'react'

export const Message = React.memo(({ item, onAction }) => {

    return <p className='message' onClick={onAction}>
        {item.verified ? <div className='saltiz'>
            <span style={{ color: item.color }}>{item.nickName}</span>
            <img className='verified' src={require('../../style/imgs/verified-white.png')} />
        </div> :
            <span style={{ color: item.color }}>{'‏' + item.nickName} : </span>}
        <span>{'‏' + item.text}</span>
    </p>
})
