import { isMobile } from 'react-device-detect'

export function Download() {
    const ios = 'https://apps.apple.com/il/app/pikmetv/id6739505062'
    const android = 'https://play.google.com/store/apps/details?id=com.anonymous.pikmeTV'
    const link = /iPhone|iPad|iPod/.test(navigator.userAgent) ? ios : android
    return <div className='download' style={{ flex: 1 }}>
        <img style={{ width: '150px', objectFit: 'contain' }} src={require('../../src/style/imgs/logo.webp')} />
        {isMobile ? <>
        <p>על מנת להנות מpikmeTV במכשיר הנייד יש להוריד את האפליקציה, מחכים לכם!</p>
        <button onClick={() => window.open(link, '_blank').focus()}>להורדה בחנות</button>
        </>  : <>
            <p>סרקו את הברקוד כדי להוריד את האפליקציה לנייד :</p>
            <img src={require('../style/imgs/barcode.jpeg')} style={{ width: '400px', height: '400px' }} />
        </>}
    </div>
}