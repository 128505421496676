import { useSelector, useDispatch } from "react-redux"
import { userService } from "../../services/user"
import { removeFromBlockList } from "../../store/reducers/user"
import { useState, useEffect } from "react"
import { Loader } from "../loader"
import { Error } from "../error"

export function BlockList({ close }) {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const [blocked, setBlocked] = useState('loading')

    useEffect(() => {
        loadBlockedUsers()
    }, [])

    const loadBlockedUsers = async () => {
        try {
            setBlocked('loading')
            const bl = await userService.getBlockedUsers()
            console.log(bl)
            setBlocked(bl)
        }
        catch (err) {
            console.log(err)
            setBlocked('error')
        }
    }

    const unblockUser = async (userId) => {
        try {
            await userService.unBlockUser(userId)
            dispatch(removeFromBlockList(userId))
            setBlocked(blocked.filter(b => b._id !== userId))
        }
        catch (err) {
            console.log(err)
            setBlocked('error')
        }
    }

    const renderInside = () => {
        if (blocked === 'loading') return <Loader type='center' />
        if (blocked === 'error') return <Error height={300} reload={loadBlockedUsers} />
        if (!blocked.length) return <p>לא חסמת אף משתמש עדיין.</p>
        return <div className="inner">
            {blocked.map(b => <div key={b._id} className="blocked-user">
                <div>
                    <img src={b.image} />
                    <p>{b.nickName}</p>
                </div>
                <button onClick={() => unblockUser(b._id)} >בטל חסימה</button>
            </div>)}
        </div>
    }

    return <div className="block-list">
        <h1>משתמשים שחסמתי :</h1>
        {renderInside()}
        <div className="close" onClick={close}>סגור</div>
    </div>
}