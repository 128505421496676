import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import { setStreamingShow } from "../../store/reducers/general"

export function AfterPay({ close }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { nextShow } = useSelector((storeState) => storeState.general)

    const goToStream = () => {
        dispatch(setStreamingShow(nextShow))
        navigate('/stream')
        close()
    }

    return <div className="after-pay simple-popup">
        <img src={nextShow.img} alt='show' />
        <p>כרטיסים בעבור {nextShow.title} נרכשו בהצלחה</p>
        <div className="buttons-wrapper" style={{ marginTop: '10px' }}>
            <div className="lighter" style={{ borderWidth: 0 }} onClick={close}>סגור</div>
            <div className="bolder" onClick={goToStream}>להופעה</div>
        </div>
    </div>
}