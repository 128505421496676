import { useNavigate, useLocation } from "react-router-dom"
const footerRemove = ['/chat','/stream']

export function Footer() {
    const navigate = useNavigate()
    const location = useLocation()
    const footer = true

    if (!footer || footerRemove.includes(location.pathname)) return <></>

    try {
        return <div className='footer'>
            <p onClick={() => navigate('/delete-account')}>מחק חשבון</p>
            <p onClick={() => window.open('https://www.privacypolicyonline.com/live.php?token=BSvr42ubTJcEKnEu7WdkM3qMJ7Dozlla', '_blank').focus()}>תנאי שימוש</p>
            <p onClick={() => navigate('/privacy-policy')}>מדיניות פרטיות</p>
            <p onClick={() => window.open('https://homericentertainment.com/', '_blank').focus()}>הומריק בידור בע"מ®</p>
            <section className="contact">
                <p>הצטרפו לקהילה של PikmeTV :</p>
                <a href="https://www.instagram.com/saltiz_il" target="_blank"><img src={require('../style/imgs/social/instagram.webp')} /></a>
                <a href="https://www.youtube.com/@thesaltiz" target="_blank"><img src={require('../style/imgs/social/youtube.webp')} /></a>
                <a href="https://www.tiktok.com/@thesaltiz" target="_blank"><img src={require('../style/imgs/social/tiktok.webp')} /></a>
            </section>
        </div>
    }
    catch (err) {
        console.log(err)
        return <></>
    }
}
