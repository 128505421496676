
import { useDispatch } from 'react-redux'
import { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { completeBoarding, setImage, setNickName } from '../store/reducers/user.js'
import { setUpperPopup } from '../store/reducers/general.js'
import { userService } from '../services/user.js'
import { httpService } from '../services/http.js'
import { Loader } from '../cmps/loader'
import { Wallpaper } from '../cmps/wallpaper'

export function Boarding() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [image, setLocalImage] = useState(null)
    const nameRef = useRef(null)
    const dateRef = useRef(null)

    useEffect(() => {
        document.getElementById('header').classList.add('hidden')
        return () => document.getElementById('header').classList.remove('hidden')
    }, [])

    async function handleSelectImage(ev) {
        try {
            if (!ev.target.files[0]) return
            if (!ev.target.files[0].type.startsWith('image/')) return dispatch(setUpperPopup('image-only'))
            setLocalImage(ev.target.files[0])
        }
        catch (err) {
            dispatch(setUpperPopup('errorServer'))
        }
    }

    async function completeProfile(save) {
        try {
            if (save) {
                const name = nameRef.current.value
                const date = new Date(dateRef.current.value)
                if (!name) return dispatch(setUpperPopup('fill-name'))
                let change = { didBoarding: true }
                change.nickName = name
                setLoading(true)
                if (image) {
                    const i = await httpService.upload(image)
                    change.image = i
                }
                if (date && Date.now() > date.getTime() + 3_600_000) change.birthDate = date
                await userService.updateUser(change)
                if (change.image) dispatch(setImage(change.image))
                dispatch(setNickName(change.nickName))
                dispatch(completeBoarding())
                return navigate('/')
            }
            userService.updateUser({ didBoarding: true })
            navigate('/')
        }
        catch (err) {
            setLoading(false)
            if (err.message.includes('409')) dispatch(setUpperPopup('image-inaproppriate'))
            else dispatch(setUpperPopup('error-server'))
        }
    }

    return <div className="inner-layout welcome">
        <Wallpaper />
        <img src={require('../style/imgs/logo.webp')} className='logo-middle' accessibilityLabel='לוגו' />
        <div className='inner-small boarding'>
            <h1 className="heading">הפרופיל שלי</h1>

            <div className="inputs">
                <div style={{ flex: 1 }}>
                    <p className="field">שם משתמש</p>
                    <input className="input" placeholder="הקלד שם משתמש" type="text" maxLength={12} ref={nameRef} />

                    <p className="field">תאריך לידה</p>
                    <input type="date" ref={dateRef} />
                </div>
                <input accept="image/jpeg, img/webp, img/avix" className='non-appear' type="file" id="img" onChange={handleSelectImage} />
                <label htmlFor='img'>
                    {image ? <img src={URL.createObjectURL(image)} alt="תמונת הפרופיל החדשה שלך" style={{ borderRadius: '50%' }} /> :
                        <img src={require('../style/imgs/upload-img.webp')} alt="בחר תמונת פרופיל" />}
                </label>
            </div>

            <div className="buttons">
                {loading ? <Loader center={true} /> :
                    <>
                        <button className="bolder" onClick={() => completeProfile(true)}>סיים הרשמה</button>
                        <button className="lighter" onClick={() => completeProfile(false)}>דלג</button>
                    </>}
            </div>
        </div>
    </div>
}