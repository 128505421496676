import { useDispatch } from "react-redux"
import { setPopup } from "../store/reducers/general"

export function Error({ reload = () => window.location.reload(), fullScreen = true }) {
    const dispatch = useDispatch()

    return <div className={`error ${fullScreen ? 'inner-layout' : ''}`}>
        <img src={require('../style/imgs/error.webp')} alt='error' />
        <h1>המידע אינו זמין כעת.</h1>
        <p>אופס! משהו השתבש, נסו <span onClick={() => reload()}>לטעון את המידע מחדש</span>.</p>
        <p>אם המידע עדיין אינו זמין צרו קשר עם התמיכה <span onClick={() => { dispatch(setPopup('support')) }}>כאן</span>.</p>
    </div>
}