const numbers = new Set()
while (numbers.size < 3) {
    const randomNumber = Math.floor(Math.random() * 23)
    numbers.add(randomNumber)
}
const n = Array.from(numbers)

export function Threeimages() {

    return <div className="three-images">
        <img src={imgs[1]} style={{left: 34 }} />
        <img src={imgs[n[1]]} style={{left: 17 }} />
        <img src={imgs[n[0]]} style={{left: 0 }} />
    </div>
}

const imgs = [
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735838758/samples/man-portrait.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735831839/zn5j5ipsvnfr4ijj5wzq.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735765778/wzm2sjjmhavjzhv6xz05.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735754411/ziwvmp0703ownnnuolin.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735658482/eyudh9crqiovogrwejv4.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735640856/hrkytwslgamogadhgtdv.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735637137/fxpimh4blex7fkdoqrly.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735632576/yys9umnywkhyjris1pdk.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735584409/zffehwbrtfs5gy8fegqu.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735575275/rkbdsrecxbd5qnm5o1s1.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735573530/wmirekibyszfhrq4tdrh.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735572189/ow89kooq9khailf00ntx.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735571847/cn00ybn1u1xlgristwlr.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735571010/u3eacsa08dg2ivsgvbsp.png',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735565379/khhqfnzoioctiwfespz3.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735557919/zcl4lkxtvw59pr2d17qz.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735555866/tnttk53ywnhmtrqcsf4m.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735555394/ffnarztuo4dhqzb0hauu.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735554628/x5uvvb89kfqes1gjnzuy.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735553111/k5kh54wuouzgzwbl76xg.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735550882/pv9essk0rscxcunfkgxm.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735543519/duskysvwfcpxf1i4mujt.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1735535329/fjfexltidxqjjuetykxx.jpg',
    'https://res.cloudinary.com/dfmtbntrc/image/upload/v1741204495/l96o7y9ygqx5aqn9w7gw.jpg'
]