import { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setPopup, setPopupInfo, setUpperPopup, setChatCache } from '../store/reducers/general'
import { io } from 'socket.io-client'
import { TextInput } from '../cmps/chat/text-input.jsx'
import { BroadMessage } from '../cmps/chat/broad-message.jsx'
import { Muted } from '../cmps/chat/muted.jsx'
import { Header } from '../cmps/chat/header.jsx'
import { Pinned } from '../cmps/chat/pinned.jsx'
import { Banned } from '../cmps/banned.jsx'
import { Welcome } from '../cmps/welcome'
import { make2digits } from '../services/utils'
import { Loader } from '../cmps/loader'
const roomId = process.env.NODE_ENV === 'production' ? 'main' : 'main-test'
const miniCreator = { id: window.saltizId }
const backupNickName = "Guest" + Math.floor(Math.random() * 100000)
const colors = ['#FF5733', '#FFD700', '#00FF00', '#FF00FF', '#00FFFF', '#FF6347', '#FFD700', '#32CD32', '#DA70D6', '#87CEEB', '#FF4500', '#FF8C00', '#7CFC00', '#9400D3', '#00FF7F', '#FF1493', '#00BFFF', '#FFA07A', '#FFD700', '#00FF00', '#FF00FF', '#00FFFF', '#FF6347', '#FFD700', '#32CD32', '#DA70D6', '#87CEEB', '#FF4500', '#FF8C00'];
let socket = null

export function Chat() {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const { chatCache } = useSelector(state => state.general)
    const [messages, setMessages] = useState(chatCache || 'loading')
    const [canSend, setCanSend] = useState(true)
    const [seconds, setSeconds] = useState(0)
    const [isConnectionActive, setIsConnectionActive] = useState(true)
    const [pinned, setPinned] = useState(null)
    let nickName = user.nickName || backupNickName
    let randomColor = useRef(colors[nickName.charCodeAt(1) % colors.length] || 'white')
    const messagesBuffer = useRef([])
    const scrollRef = useRef()

    useEffect(() => {
        connectSocket()
        const interval = setInterval(() => {
            if (!messagesBuffer.current.length) return
            setMessages((prevMessages) => {
                const updated = [...prevMessages, ...messagesBuffer.current]
                messagesBuffer.current = []
                if (updated.length > 155) return updated.slice(-50)
                return updated
            })
            const toScroll = scrollRef.current.scrollTop + scrollRef.current.clientHeight === scrollRef.current.scrollHeight
            if (toScroll) setTimeout(() => scrollRef.current.scrollTop = scrollRef.current.scrollHeight, 100)
        }, 1500)

        return () => {
            if (socket) {
                if (socket) {
                    socket.off('message')
                    socket.off('message-bulk')
                    socket.off('muted')
                    socket.off('update-pinned')
                    socket.off('disconnect')
                    socket.off('connect_error')
                    socket.close()
                    socket = null
                }
            }
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        return () => dispatch(setChatCache(messages))
    }, [messages])

    const connectSocket = () => {
        if (socket) {
            socket.close()
            socket = null
        }
        socket = process.env.NODE_ENV === 'production' ? io('https://chat-main.pikme.tv', { transports: ['websocket'] }) : io('https://chat-main.pikme.tv', { transports: ['websocket'] })

        socket.emit('joinRoom', { userId: user.id })
        socket.on('disconnect', () => setIsConnectionActive(false))
        socket.on('connect_error', () => setIsConnectionActive(false))
        socket.on('message', (message) => messagesBuffer.current.push(message))
        socket.on('message-bulk', (messages) => {
            setMessages(messages)
            setTimeout(() => { if (scrollRef?.current) scrollRef.current.scrollTop = scrollRef.current.scrollHeight }, 200)
        })
        socket.on('update-pinned', (pinned) => setPinned(pinned))
        socket.on('muted', (time) => setSeconds(Math.ceil((time - Date.now()) / 1000)))
        socket.on('wait', () => dispatch(setUpperPopup('warning-message-rate')))
        setIsConnectionActive(true)
    }

    const sendMessage = (input) => {
        if (input === '') return
        if (input.length > 300) return dispatch(setPopup('warning-message-length'))
        if (!canSend) return dispatch(setUpperPopup('warning-message-rate'))
        const date = new Date()
        const formatted = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${make2digits(date.getMinutes())}`
        const newMessage = { text: input, nickName: nickName, color: randomColor.current, roomId: roomId, userId: user.id, image: user.image, date: formatted }
        socket.emit('chat', newMessage)
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight
        setCanSend(false)
        setTimeout(() => setCanSend(true), 10_000)
    }

    const showUser = (message) => {
        if (message.userId === user.id || (message.nickName === 'הודעת מערכת' && !message.userId) || message.userId === miniCreator.id) return
        dispatch(setPopupInfo(message))
        dispatch(setPopup('block-user'))
    }

    const renderItem = useCallback((item) => {
        if (user?.blockList[item.userId]) return null
        return <BroadMessage item={item} key={item.id} onClick={() => showUser(item)} miniCreator={miniCreator} />
    }, [user.blockList, showUser])

    const renderLower = () => {
        if (!isConnectionActive) return <div onClick={connectSocket} className='reconnect'><p>לחץ להתחברות מחדש</p></div>
        if (seconds > 0) return <Muted initialSeconds={seconds} setOuterSeconds={setSeconds} />
        return <TextInput onAction={sendMessage} />
    }

    if (!user.id) return <Welcome />

    if (user.banned) return <Banned />

    return <div className='inner-layout' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
        <div className='chat'>
            <Header />
            {pinned && <Pinned pinned={pinned} />}
            <div className='messages-container' ref={scrollRef}>
                {messages === 'loading' ? <Loader center={true} /> : messages.map((item) => renderItem(item))}
            </div>
            {renderLower()}
        </div>
    </div>
}