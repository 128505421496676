import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { showService } from '../services/show'
import { CountDown } from './countdown'
import { setNextShow } from '../store/reducers/general'
import { DateTime } from './date-time'
import { Wallpaper } from './wallpaper'
export function Welcome() {
    const { nextShow } = useSelector((storeState) => storeState.general)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if (nextShow === 'no-shows' || nextShow.date < Date.now() - (3 * 60 * 60 * 1000)) reloadNextShow()
        document.getElementById('header').classList.add('hidden')
        return () => document.getElementById('header').classList.remove('hidden')
    }, [])

    const reloadNextShow = async () => {
        try {
            dispatch(setNextShow('loading'))
            const loadedShow = await showService.getNextShow()
            if (loadedShow.date === 0) return dispatch(setNextShow('no-shows'))
            dispatch(setNextShow(loadedShow))
        }
        catch (err) {
            dispatch(setNextShow('error'))
        }
    }

    return <div className='inner-layout welcome'>
        <img src={require('../style/imgs/logo.webp')} className='logo-middle' accessibilityLabel='לוגו' />
        <Wallpaper />
        {(nextShow === 'no-shows' || nextShow === 'error' || nextShow === 'loading') ?
            <div className='inner-small space-between-vertical' style={{ flex: 1 }}>
                <img className='background' src={require('../style/imgs/wallpaper.jpg')} accessibilityLabel='תמונה של הסולטיז' />
                <div className='half'>
                    <p className='title'>ברוכים הבאים לPikmeTV</p>
                    <p className='subtitle'>האתר הבלעדי של הסולטיז</p>
                </div>

                <div className='half' style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
                    <div onClick={() => navigate('/login')} className='button'>להרשמה</div>
                </div>
            </div>
            :
            <div className='inner-small space-between-vertical' style={{ flex: 1 }}>
                <img src={nextShow.imgPc} className='background' accessibilityLabel='תמונה של ההופעה' />
                <div className='half'>
                    <p className='title'>{nextShow.title}</p>
                    <p className='subtitle'>שידור חי בעוד :</p>
                    <div style={{ marginTop: '20px' }} />
                    <CountDown showDate={nextShow.date} />
                </div>

                <div className='half' style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
                    <div className='button' onClick={() => navigate('/login')} >אני רוצה להשתתף!</div>
                    <DateTime date={new Date(nextShow.date)} />
                </div>
            </div>}
    </div>
}

