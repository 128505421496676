import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { setPopup } from "../../store/reducers/general"
import { PopupSupport } from "./support"
import { BlockUser } from "./block-user"
import { BlockList } from './block-list'
import { AfterPay } from "./after-pay"

export function Popup() {
    const dispatch = useDispatch()
    const { popup } = useSelector((storeState) => storeState.general)

    useEffect(() => {
        if (popup) document.body.classList.add("no-scroll")
        else document.body.classList.remove("no-scroll")
        return () => document.body.classList.remove("no-scroll")
    }, [popup])

    const close = () => dispatch(setPopup(''))

    if (!popup) return <></>

    return <>
        <div className="screen blur" onClick={() => dispatch(setPopup(''))}>
        </div>
        <section className='popup'>
            {popup === 'after-pay' && <AfterPay close={close} />}
            {popup === 'support' && <PopupSupport />}
            {popup === 'block-user' && <BlockUser close={close} />}
            {popup === 'block-list' && <BlockList close={close} />}
        </section>
    </>
}