import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userService } from "../../services/user"
import { setUpperPopup } from "../../store/reducers/general.js"
import { Error } from '../../cmps/error.jsx'
import { Post } from "./post.jsx"
import { Loader } from '../loader.jsx'
import { useQuery } from '@tanstack/react-query'

export function Feed({ creatorId }) {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const [feed, setFeed] = useState([])
    const [from, setFrom] = useState(0)
    const [loader, setLoader] = useState('')
    const { data: initialFeed, isLoading, error, refetch } = useQuery({ queryKey: ['initial-feed'], queryFn: loadInitialFeed, staleTime: 10 * 60 * 1000 })

    useEffect(() => {
        if (from > 1) loadMoreFeed()
    }, [from])

    useEffect(() => {
        if (initialFeed) {
            if (!feed.length) setFeed(initialFeed)
            if (initialFeed.length === 10) setLoader('showMore')
        }
    }, [initialFeed])

    async function loadInitialFeed() {
        const f = await userService.getCreatorFeed(creatorId, from, user.token)
        return f
    }

    const loadMoreFeed = async () => {
        try {
            if (loader === 'showMore') setLoader('loading')
            const f = await userService.getCreatorFeed(creatorId, from, user.token)
            setFeed(prevFeed => [...prevFeed, ...f])
            if (f.length < 10) setLoader('')
            else setLoader('showMore')
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('error-server'))
            setLoader('error')
        }
    }

    const renderFooter = () => {
        if (!loader) return null
        if (loader === 'loading') return  <Loader type='center'/>
           
        if (loader === 'showMore') return <div className='show-more' onClick={() => setFrom(from + 10)}>
            <p>הצג עוד</p>
            <span className="material-symbols-outlined">keyboard_arrow_down</span>
        </div>
    }

    if (error) return <Error reload={refetch} />

    if (isLoading) return <Loader type='center'/>

    try {
        return <div className="feed">
            {feed.map((item, idx) => <Post user={user} post={item} key={idx} />)}
            {renderFooter()}
        </div>
    }
    catch {
        return <Error />
    }
}