import { useDispatch, useSelector } from 'react-redux'
import { addToBlockList } from '../../store/reducers/user'
import { setUpperPopup } from '../../store/reducers/general'
import { userService } from '../../services/user'

export function BlockUser({ close }) {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const { popupInfo } = useSelector(state => state.general)
    const { image, userId, nickName, text } = popupInfo

    const blockUser = async (report = false) => {
        try {
            await userService.blockUser(userId)
            dispatch(addToBlockList(userId))
            if (report) {
                const reportDeails = {
                    subject: 'Report about inappropriate chat message',
                    description: `user : ${user.id} - ${user.nickName} reported user : ${userId} - ${nickName} for inappropriate chat message : ${text}`,
                    email: 'irelevant',
                    name: user.nickName,
                }
                await userService.reportUser(reportDeails)
                dispatch(setUpperPopup('reported-user'))
            }
            else dispatch(setUpperPopup('blocked-user'))
            close()
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('error-server'))
        }
    }
    return <div className='popup-block-user'>
        <div className='level'>
            <p>{nickName}</p>
            <img src={image} />
        </div>
        <button className='level' onClick={() => blockUser(false)}>חסום משתמש, לא תראה הודעות שלו יותר.</button>
        
        <button className='button' onClick={() => blockUser(true)}>חסום ודווח על משתמש, הודעה תישלח לצוות pikmeTV לבירור.</button>
        
    </div>
}