import { useDispatch } from 'react-redux'
import { setPopup } from '../store/reducers/general'

export function Banned() {
    const dispatch = useDispatch()
    return <div className='inner-layout error'>
        <img src={require('../style/imgs/error.webp')} alt='error' />
        <h1>משתמש בהשעיה.</h1>
        <p>אנו מצטערים להודיע שהושעית מהאתר בעקבות התנהגות בלתי הולמת.</p>
        <p>אם קיים חשד שחלה טעות, ניתן לפנות אל התמיכה <span onClick={() => { dispatch(setPopup('support')) }}>כאן</span>.</p>
    </div>
}