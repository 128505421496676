import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import { useSelector } from "react-redux"
import { userService } from '../services/user.js'
import { showService } from '../services/show.js'
import { Error } from '../cmps/error.jsx'
import { Loader } from '../cmps/loader.jsx'
import { setNextShow, setPopup, setPopupInfo, setUpperPopup, setStreamingShow } from '../store/reducers/general.js'
import { addPurchase } from '../store/reducers/user.js'
import { Welcome } from '../cmps/welcome.jsx'
import { Banned } from '../cmps/banned.jsx'
import { DateTime } from '../cmps/date-time.jsx'
import { CountDown } from "../cmps/countdown.jsx"
import { Threeimages } from "../cmps/3images.jsx"
import { Wallpaper } from "../cmps/wallpaper.jsx"

export function Tickets() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((storeState) => storeState.user)
    const { nextShow } = useSelector((storeState) => storeState.general)
    const [hasStarted, setHasStarted] = useState(false)

    useEffect(() => {
        if (nextShow === 'no-shows' || nextShow.date < Date.now() - (3 * 60 * 60 * 1000)) reloadNextShow()
        // else if (user.purchases && nextShow._id && user.purchases[nextShow._id]) goToStream()
    }, [])

    const reloadNextShow = async () => {
        try {
            dispatch(setNextShow('loading'))
            const loadedShow = await showService.getNextShow()
            if (loadedShow.date === 0) return dispatch(setNextShow('no-shows'))
            dispatch(setNextShow(loadedShow))
        }
        catch (err) {
            dispatch(setNextShow('error'))
        }
    }

    const buyTicket = async () => {
        try {
            const miniShow = await userService.purchaseTicket(nextShow._id, user.token)
            dispatch(addPurchase(miniShow))
            dispatch(setPopupInfo(nextShow))
            dispatch(setPopup('after-pay'))
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('error-server'))
        }
    }

    const goToStream = () => {
        dispatch(setStreamingShow(nextShow))
        navigate('/stream')
    }

    if (!user.nickName) return <Welcome />

    if (user.banned) return <Banned />

    if (nextShow === 'loading') return <Loader type='full' />

    if (nextShow === 'error') return <Error reload={reloadNextShow} />

    if (nextShow === 'no-shows') return <div className="no-tickets-wrapper">
        <div className="no-tickets">
            <p>אין הופעות שעתידות להתחיל כרגע.</p>
            <p onClick={() => navigate('/')} className='clickable main-color'>חזרה לעמוד הבית</p>
        </div>
    </div>

    return <div className='inner-layout'>
        <Wallpaper />
        <div className="narrow-layout tickets">
            <div>
                <img src={nextShow.imgPc} className='show-banner' />
                <div className="upper">
                    <div>
                        <h1>{nextShow.title}</h1>
                        <div style={{ width: '253px' }}><DateTime date={new Date(nextShow.date)} color='main' /></div>
                    </div>
                    {!hasStarted && <CountDown className="countdown countdown-mini" showDate={nextShow.date} onEnd={() => setHasStarted(true)} />}
                </div>

                {showService.ticketsAmount > 5 && <div className="three-wrapper">
                    <Threeimages />
                    <p>+ {nextShow.ticketsAmount} משתתפים</p>
                </div>}
                <h3>על האירוע</h3>
                <p>{nextShow.longDescription}</p>
            </div>
            {user.purchases[nextShow._id] ? <button className={hasStarted ? 'pulsing' : ''} onClick={goToStream}>כניסה להופעה</button> :
                <button onClick={buyTicket}>השג כרטיס</button>
            }
        </div>
    </div>
}
