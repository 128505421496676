import { useState, useRef,useEffect } from "react"
import { useNavigate } from "react-router"
import { useDispatch } from "react-redux"
import { userService } from "../services/user"
import { login } from "../store/reducers/user"
import { setUpperPopup } from "../store/reducers/general"
import { Error } from "../cmps/error"
import { Wallpaper } from "../cmps/wallpaper"

export function Login() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [phone, setPhone] = useState(null)
    const codeRef = useRef()
    const phoneRef = useRef()

    useEffect(() => {
        document.getElementById('header').classList.add('hidden')
        return () => document.getElementById('header').classList.remove('hidden')
    }, [])

    const handlePhone = async (ev) => {
        if (ev) ev.preventDefault()
        const ph = phoneRef.current.value
        if (ph.length < 9 || ph.length > 11) return dispatch(setUpperPopup('invalidPhone'))
        const formatted = '972' + (ph[0] === '0' ? ph.slice(1) : ph)
        try {
            await userService.sendOtp(Number(formatted))
            setPhone(formatted)
        }
        catch (err) {
            if (err.message?.includes('405')) dispatch(setUpperPopup('wait-before-otp'))
            else if (err.message?.includes('406')) dispatch(setUpperPopup('too-many-otp'))
            else dispatch(setUpperPopup('error-server'))
        }
    }

    const submitCode = async (ev) => {
        ev.preventDefault()
        if (!codeRef.current.value) return
        try {
            const loadedUser = await userService.validateOtp(phone, codeRef.current.value)
            dispatch(login(loadedUser))
            if (!loadedUser.didBoarding) return navigate('/boarding')
            navigate('/')

        }
        catch {
            dispatch(setUpperPopup('error-code'))
        }
    }

    try {
        return <section className='inner-layout welcome'>
            <Wallpaper />
            <img src={require('../style/imgs/logo.webp')} className='logo-middle' accessibilityLabel='לוגו' />
            <div className="inner-small login" style={{ height: '517px' }}>
                {!phone && <>
                    <h1>הזן מספר טלפון</h1>
                    <form onSubmit={handlePhone}>
                        <div>
                            <p className="instructions">קוד בעל 6 ספרות ישלח אליך בדקות הקרובות לנייד.</p>
                            <input type='number' ref={phoneRef} placeholder='...05' required />
                        </div>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-start', flexDirection: 'column' }}>
                            <p className="privacy">בלחיצה על הכפתור למטה אני מסכים ל<span onClick={() => window.open('https://www.privacypolicyonline.com/live.php?token=BSvr42ubTJcEKnEu7WdkM3qMJ7Dozlla', '_blank').focus()}>תנאי השימוש</span> ול <span onClick={() => navigate('/privacy-policy')}>מדיניות הפרטיות</span>.</p>
                            <button className="button">להרשמה</button>
                        </div>
                    </form>
                </>}
                {phone && <>
                    <h1>הזן את הקוד</h1>
                    <form onSubmit={submitCode}>
                        <div>
                            <p className="instructions">שלחנו קוד למספר : <span style={{ opacity: '1' }}>{'0' + phone.slice(3)}</span></p>
                            <input type='number' ref={codeRef} required placeholder="הקלד את הקוד כאן"/>
                            <p onClick={()=>setPhone(null)} className="wrong">טעיתי במספר</p>
                        </div>

                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-start', flexDirection: 'column' }}>
                            <button className="button">המשך</button>
                        </div>
                    </form>
                </>}
            </div>
        </section>
    }
    catch {
        return <Error />
    }
}