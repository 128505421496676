import React, { useState, useRef, useEffect } from "react"
import { useSelector } from "react-redux"
import { Chat } from "../cmps/chat/chat"
import { CountDown } from "../cmps/countdown"
import { Error } from "../cmps/error"
import { httpService } from "../services/http"
const AgoraRTC = require('agora-rtc-sdk-ng')
const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" })
AgoraRTC.setLogLevel(2)
const APP_ID = '2148ba0fc4934b56b78fc915f29945f1'
let channelParameters = { localAudioTrack: null, localVideoTrack: null }

function debounce(func, delay) {
    let timeoutId;
    const debouncedFunc = (...args) => {
        if (timeoutId) clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            func(...args)
        }, delay)
    }
    debouncedFunc.cancel = () => {
        if (timeoutId) clearTimeout(timeoutId)
    }
    return debouncedFunc
}

export function Stream() {
    const show = useSelector(state => state.general.streamingShow)
    const user = useSelector(state => state.user)
    const [isMuted, setIsMuted] = useState(false)
    const [isLive, setIsLive] = useState(false)
    const [msg, setMsg] = useState('סולטיז לא משדרים כרגע...')
    const [bar, setBar] = useState(false)
    const [viewers, setViewers] = useState(0)
    const [isFullScreen, setIsFullScreen] = useState(false)
    let reloadInterval = useRef(null)
    const miniCreator = show.miniCreator
        ? show.miniCreator
        : { id: window.saltizId, nickName: 'סולטיז', image: 'https://res.cloudinary.com/dfmtbntrc/image/upload/v1736251723/syicip13bsosrtrjzdim.jpg' }

    const debouncedSetBarFalse = useRef(
        debounce(() => {
            setBar(false)
        }, 2000)
    )

    const handleMouseMove = () => {
        setBar(true)
        debouncedSetBarFalse.current()
    }

    useEffect(() => {
        init()
        return () => cleanup()
    }, [])

    const cleanup = () => {
        if (channelParameters.localAudioTrack && channelParameters.localAudioTrack !== "") {
            channelParameters.localAudioTrack.stop()
            channelParameters.localAudioTrack = ""
        }
        if (channelParameters.localVideoTrack && channelParameters.localVideoTrack !== "") {
            channelParameters.localVideoTrack.stop()
            channelParameters.localVideoTrack = ""
        }
        client.removeAllListeners()
        client.leave()
        debouncedSetBarFalse.current.cancel()
    }

    const init = async () => {
        let channelName = show._id
        try {
            const uid = Math.floor(Math.random() * 10000)
            const token = await httpService.get(`handle-stream/${show._id}/${uid}`)
            await client.join(APP_ID, String(channelName), token, uid)

            client.on("user-published", async (user, mediaType) => {
                await client.subscribe(user, mediaType)
                if (reloadInterval.current) clearInterval(reloadInterval.current)
                if (mediaType === "video") {
                    channelParameters.localVideoTrack = user.videoTrack
                    setMsg('')
                    channelParameters.localVideoTrack.play("agora_remote")
                    let element = document.getElementsByClassName("agora_video_player")[0]
                    element.controls = false
                }
                if (mediaType === "audio") {
                    channelParameters.localAudioTrack = user.audioTrack
                    channelParameters.localAudioTrack.play()
                }
            })

            client.on("user-unpublished", async (user, mediaType) => {
                await client.unsubscribe(user, mediaType)
                if (mediaType === "audio") {
                    if (channelParameters.localAudioTrack && channelParameters.localAudioTrack !== "") {
                        channelParameters.localAudioTrack.stop()
                        channelParameters.localAudioTrack = ""
                        setMsg('סולטיז לא משדרים כרגע...')
                        reloadInterval.current = setInterval(() => {
                            setMsg('סולטיז לא משדרים כרגע...')
                        }, 4000)
                    }
                }
                if (mediaType === "video") {
                    if (channelParameters.localVideoTrack && channelParameters.localVideoTrack !== "") {
                        channelParameters.localVideoTrack.stop()
                        channelParameters.localVideoTrack = ""
                    }
                }
            })
        }
        catch (e) {
            console.log("join failed", e)
        }
    }

    function muteVideo(mute) {
        if (!channelParameters?.localAudioTrack) return
        if (mute) {
            channelParameters.localAudioTrack.setVolume(0)
            setIsMuted(true)
        }
        else {
            channelParameters.localAudioTrack.setVolume(100)
            setIsMuted(false)
        }
    }

    const handleFullScreen = () => {
        const video = document.getElementById('agora_remote')
        if (!isFullScreen) {
            if (video.requestFullscreen) video.requestFullscreen()
            else if (video.mozRequestFullScreen) video.mozRequestFullScreen()
            else if (video.webkitRequestFullscreen) video.webkitRequestFullscreen()
            else if (video.msRequestFullscreen) video.msRequestFullscreen()
            setIsFullScreen(true)
        }
        else {
            if (document.exitFullscreen) document.exitFullscreen()
            else if (document.mozCancelFullScreen) document.mozCancelFullScreen()
            else if (document.webkitExitFullscreen) document.webkitExitFullscreen()
            else if (document.msExitFullscreen) document.msExitFullscreen()
            setIsFullScreen(false)
        }
    }

    try {
        return (
            <div className="inner-layout stream">
                <Chat setViewers={setViewers} user={user} showId={show._id} miniCreator={miniCreator} />
                <div className="stream-inner">
                    <div
                        id='agora_remote'
                        onDoubleClick={handleFullScreen}
                        onMouseMove={handleMouseMove}  // Trigger on mouse movement
                    >
                        {(!msg && bar) && (
                            <div className="stream-bar noselect">
                                <div className="center-row-10">
                                    {isFullScreen ? (
                                        <span className="material-symbols-outlined clickable" onClick={handleFullScreen}>
                                            fullscreen_exit
                                        </span>
                                    ) : (
                                        <span className="material-symbols-outlined clickable" onClick={handleFullScreen}>
                                            fullscreen
                                        </span>
                                    )}
                                    {isMuted ? (
                                        <span className="material-symbols-outlined clickable" onClick={() => muteVideo(false)}>
                                            volume_off
                                        </span>
                                    ) : (
                                        <span className="material-symbols-outlined clickable" onClick={() => muteVideo(true)}>
                                            volume_up
                                        </span>
                                    )}
                                    <img src={require('../style/imgs/rec.png')} alt="live" style={{ width: '30px', height: '30px' }} />
                                </div>
                                <p>{show.title}</p>
                                <div className="center-row-10">
                                    <p>{viewers}</p>
                                    <img className="viewers-img" src={require('../style/imgs/viewers.webp')} alt="viewers" />
                                </div>
                            </div>
                        )}
                    </div>
                    {!isLive ? (
                        <div className='over-video'>
                            <CountDown showDate={show.date} onEnd={setIsLive} />
                        </div>
                    ) : msg ? (
                        <div className='over-video'>
                            <p className="msg">{msg}</p>
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }
    catch (err) {
        console.log(err)
        return <Error fullScreen={true} />
    }
}
