import { useEffect, useState, useRef } from 'react'

export function Muted({ setOuterSeconds, initialSeconds }) {
    const [seconds, setSeconds] = useState(initialSeconds)
    const timerRef = useRef()

    useEffect(() => {
        setSeconds(initialSeconds)

        if (initialSeconds > 0) timerRef.current = setInterval(() => {
            setSeconds((prev) => prev - 1)
        }, 1000)

        return () => clearInterval(timerRef.current)
    }, [initialSeconds])

    useEffect(() => {
        if (seconds <= 0) {
            clearInterval(timerRef.current)
            setOuterSeconds(0)
        }
    }, [seconds])

    return <div>
        <p>קיבלת השתקה זמנית מהצ'אט בעקבות התנהגות לא ראויה. תוכל לכתוב שוב בעוד {String(seconds)} שניות.</p>
    </div>
}